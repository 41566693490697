import React from "react";
import { Form, Button, Row, Col, Modal } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import es from "react-phone-number-input/locale/es";
import "react-phone-number-input/style.css";
import useContactForm from "../../../hooks/useContactForm";

function ContactForm() {
  const { show, formData, handleClose, handleChange, handlePhoneChange, handleSubmit } = useContactForm();

  return (
    <Form className="cta-contact-form-submit py-4" onSubmit={handleSubmit}>
      <Row>
        <Col md={6}>
          <Form.Group controlId="name">
            <Form.Label>Nombre*</Form.Label>
            <Form.Control className="form-control-border" type="text" placeholder="Nombre" name="name" value={formData.name} onChange={handleChange} required />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="company">
            <Form.Label>Empresa*</Form.Label>
            <Form.Control className="form-control-border" type="text" placeholder="Empresa" name="company" value={formData.company} onChange={handleChange} required />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group controlId="email">
            <Form.Label>Email*</Form.Label>
            <Form.Control className="form-control-border" type="email" placeholder="Email" name="email" value={formData.email} onChange={handleChange} required />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="telefono">
            <Form.Label>Teléfono*</Form.Label>
            <PhoneInput defaultCountry="ES" international labels={es} name="phone" value={formData.phone} onChange={handlePhoneChange} required />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group controlId="message">
        <Form.Label>Mensaje</Form.Label>
        <Form.Control as="textarea" rows={5} placeholder="Escribe tu mensaje" name="message" value={formData.message} onChange={handleChange} />
      </Form.Group>
      <Button className="prim-btn" variant="primary" type="submit">
        Enviar Mensaje
      </Button>
      <Modal id="contactModal" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title p-2 pb-0 prim-color">¡Gracias por contactar con nosotros!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <p>En breve nos pondremos en contacto contigo.</p>
          <p>
            Si tienes alguna pregunta o deseas obtener más información, no dudes en escribirnos a{" "}
            <a className="prim-color fw-bold" href="mailto:contacto@oakmanvs.com">
              contacto@oakmanvs.com
            </a>
            .
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn prim-btn" onClick={handleClose}>
            Continuar
          </button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}

export default ContactForm;
