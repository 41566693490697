import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaQuoteLeft } from "react-icons/fa";

function Mission() {
  return (
    <>
      <Container>
        <Row className="section-row">
          <Col lg={6} className="section-col-md">
            <p className="mission-title thrd-color">
              <span className="sec-color">Sí</span>, la Inteligencia Artificial también <span className="sec-color">es para ti</span>
            </p>
            <p className="thrd-color">
              La irrupción de la Inteligencia Artificial ha revolucionado la forma en la que consumimos y la forma en la que nos relacionamos con las empresas. Su adopción se ha convertido en una
              prioridad estratégica para aquellas empresas que buscan mejorar su ventaja competitiva y mantener la relevancia de sus productos y servicios en un entorno cada vez más competitivo.
            </p>
            <p className="thrd-color mb-4">
              En OAKMAN creemos que esta transformación nos afecta a todos, y <span className="fw-bold">nuestra misión es asesorar y acompañar a empresas como la tuya durante esta nueva etapa.</span>
            </p>
            <div className="d-flex align-items-start mb-2">
              <FaQuoteLeft className="mission-quote me-2" />
              <div>
                <p className="thrd-color fw-light mb-0">Si te estás preguntando:</p>
                <span className="mission-question sec-color d-block">¿Cómo puede mejorar la Inteligencia Artificial mi negocio?</span>
              </div>
            </div>
          </Col>
          <Col lg={6} className="text-center d-none d-lg-block">
            <img src={require("./imgs/mission-img.webp")} alt="Mission" className="" width="70%" height="100%" />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Mission;
