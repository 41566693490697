import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Benefits() {
  const sections = [
    {
      imgSrc: require("./imgs/benefits-automation.webp"),
      title: "Automatiza",
      color: "prim-color",
      separator: "text-separator-green",
      text: "Elimina tareas repetitivas para dedicarte a las acciones que aportan valor.",
    },
    {
      imgSrc: require("./imgs/benefits-analyze.webp"),
      title: "Analiza",
      color: "sec-color",
      separator: "text-separator-yellow",
      text: "Toma decisiones informadas en base a los datos reales de tu empresa.",
    },
    {
      imgSrc: require("./imgs/benefits-optimize.webp"),
      title: "Optimiza",
      color: "prim-color",
      separator: "text-separator-green",
      text: "Aumenta la productividad de tus recursos y reduce tus costes operativos.",
    },
    {
      imgSrc: require("./imgs/benefits-innovate.webp"),
      title: "Innova",
      color: "sec-color",
      separator: "text-separator-yellow",
      text: "Crea productos y servicios innovadores, y mantén tu ventaja competitiva.",
    },
  ];

  return (
    <>
      <Container>
        <Row className="section-row">
          <Col className="section-col-md px-lg-0">
            <p className="benefits-title prim-color">
              <span className="watermark-yellow">Sorprende</span> a tus clientes. <span className="watermark-green thrd-color">Supera</span> a tu competencia.
            </p>
          </Col>
        </Row>
        <Row>
          {sections.map((section, index) => (
            <Col lg={3} md={6} xs={12} className="section-col-md mb-2 mb-lg-0" key={index}>
              <img src={section.imgSrc} alt={section.title} className="img-fluid mb-2" />
              <h3 className={section.color}>{section.title}</h3>
              <div className={section.separator}></div>
              <p>{section.text}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default Benefits;
