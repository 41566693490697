import React from "react";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import NavigationBar from "./components/common/NavigationBar/NavigationBar";
import Footer from "./components/common/Footer/Footer";
import LandingPage from "./components/landing/LandingPage";
import Legal from "./components/legal/Legal";
import PrivacyPolicy from "./components/legal/PrivacyPolicy";
import CookiesPolicy from "./components/legal/CookiesPolicy";

import "./assets/styles/App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const AppTemplate = () => (
  <>
    <header>
      <NavigationBar />
    </header>
    <main>
      <Outlet />
    </main>
    <footer>
      <Footer />
    </footer>
  </>
);

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AppTemplate />}>
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
