import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../LandingPage.css";

function Hero() {
  return (
    <div className="hero-section">
      <Container className="h-100">
        <Row className="align-items-center justify-content-center h-100">
          <Col lg={8} md={10} className="text-center">
            <h1 className="hero-title"><span className="prim-color">Inteligencia Artificial</span> <br className="hero-br"/>a medida para tu negocio</h1>
            <p className="hero-subtitle">Desarrollamos estrategias y soluciones de IA personalizadas que se adaptan a las necesidades de tu empresa</p>
            <Button className="hero-btn prim-btn" variant="primary" href="#contact">¡Contáctanos!</Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Hero;
