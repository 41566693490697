import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import ContactForm from "./ContactForm";
import "../LandingPage.css";

function Contact() {
  const contactItems = [
    { type: "Teléfono", value: "+34 696 747 490", link: "tel:+34 696 747 490" },
    { type: "Email", value: "contacto@oakmanvs.com", link: "mailto:contacto@oakmanvs.com" },
    { type: "Dirección", value: "C/ Fernández de la Hoz, 28010, Madrid, España", link: null },
  ];

  return (
    <>
      <Container>
        <Row className="justify-content-center section-row">
          <Col>
            <Card className="contact-card">
              <Card.Body>
                <Row className="section-row">
                  <Col lg={6} className="px-md-4 px-2">
                    <div className="contact-info p-4 rounded">
                      <h3 className="contact-title sec-color pb-2">Contáctanos</h3>
                      <p>
                        Cuéntanos el proyecto que te gustaría hacer y nosotros te ofrecemos una <span className="sec-color fw-bold">primera reunión totalmente gratuita.</span>
                      </p>
                    </div>
                    {contactItems.map((item, index) => (
                      <div className="contact-item px-4 py-2 rounded mt-2" key={index}>
                        <Row className="align-items-center">
                          <Col md={3}>
                            <p className="prim-color h5">{item.type}</p>
                          </Col>
                          <Col md={9}>
                            {item.link ? (
                              <p className="prim-color h6 mb-1">
                                <a href={item.link}>{item.value}</a>
                              </p>
                            ) : (
                              <p className="prim-color h6 mb-1">{item.value}</p>
                            )}
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </Col>
                  <Col lg={6} className="px-4">
                    <ContactForm />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Contact;
