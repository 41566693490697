import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import Logo from "../../../assets/imgs/oakman-logo.png";

import "./NavigationBar.css";

function NavigationBar() {
  
  return (
    <Navbar expand="lg" className="bg-color">
      <Container className="nav-container">
        <Navbar.Brand href="/">
          <img src={Logo} alt="OAKMAN" width="180px" height="auto" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="toggable-navbar-nav" />
        <Navbar.Collapse id="toggable-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/#mission">Nuestra misión</Nav.Link>
            <Nav.Link href="/#benefits">Nuestro enfoque</Nav.Link>
            <Nav.Link href="/#services">Nuestros servicios</Nav.Link>
            <Nav.Link className="nav-btn prim-btn" href="/#contact">
              <span>Contacto</span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
