import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { HelmetProvider, Helmet } from "react-helmet-async";

function CookiesHelmet({ children }) {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Política de Cookies - OAKMAN</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {children}
    </HelmetProvider>
  );
}

function CookiesPolicy() {
  return (
    <CookiesHelmet>
      <Container className="py-5">
        <Row>
          <Col className="section-col-md">
            <h1 className="prim-color pb-2">Política de Cookies</h1>
            <p>
              AL ACEPTAR EL USO DE COOKIES, EL USUARIO CONSIENTE LA DESCARGA DE LAS COOKIES Y/O TECNOLOGÍAS SIMILARES, EN EL DISPOSITIVO EN CASO DE ENCONTRARSE HABILITADA LA CORRESPONDIENTE OPCIÓN.
            </p>
            <p>
              La presente Política de Cookies regula el uso de dispositivos de almacenamiento y recuperación de datos a través del portal de Internet accesible a través de la URL https://oakmanvs.com
              (en adelante, el “Portal”) que OAKMAN VENTURE STUDIO S.L. (en adelante, “OAKMAN”) pone a disposición de los usuarios de Internet. La presente Política forma parte integrante del Aviso
              Legal accesible en todo momento desde el Portal.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="section-col-md">
            <h2 className="pt-4 h4 prim-color">1. ¿Qué son las cookies?</h2>
            <p>
              Una cookie es un pequeño archivo con información relativa a múltiples variables, como el número de visitas a la página por parte del usuario, la identificación del usuario registrado,
              asegurar la sesión del usuario mientras navega, permitir operaciones seguras, etc. Estos archivos se almacenan en el terminal del Usuario a través del navegador y se comunican con
              páginas web para personalizar la navegación según la información proporcionada.
            </p>
            <p>
              Junto a las cookies, también se utilizan otras tecnologías como etiquetas de pixel y web beacons, que tienen una función técnica analítica de carácter anónimo, como contar vistas de
              páginas o acceder a las cookies previamente instaladas en el servidor.
            </p>
            <p>
              Las cookies empleadas en el Portal por sí mismas no revelan información personal. Su finalidad analítica emplea identificadores, incluida la IP, con la exclusiva finalidad de identificar
              el número de visitantes únicos a la web, su origen geográfico o su tendencia de uso web, pero no identifican personalmente al Usuario.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="section-col-md">
            <h2 className="pt-4 h4 prim-color">2. Tipología de cookies</h2>
            <p className="pt-3 h5 prim-color">2.1. Según la entidad que las gestione</p>
            <p>Cookies propias: Son aquéllas enviadas al equipo terminal del usuario desde dominios gestionados por OAKMAN como editor de la página web.</p>
            <p>Cookies de terceros: Son aquéllas enviadas al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad.</p>
            <p className="pt-3 h5 prim-color">2.2. Según el plazo de tiempo que permanecen activadas en el equipo terminal</p>
            <p>Cookies de sesión: Diseñadas para recabar y almacenar datos mientras el usuario accede al Portal, se eliminan al finalizar la sesión.</p>
            <p>Cookies persistentes: Los datos siguen almacenados y pueden ser accedidos durante un periodo definido por el responsable de la cookie.</p>
            <p className="pt-3 h5 prim-color">2.3. Según su finalidad</p>
            <p>Cookies estrictamente necesarias: Permiten la navegación y el uso de opciones o servicios disponibles en el Portal.</p>
            <p>Cookies dirigidas: Permiten gestionar eficazmente los espacios publicitarios en el Portal, desarrollando un perfil para mostrar publicidad según los hábitos de navegación.</p>
            <p>Cookies de rendimiento: Diseñadas para el seguimiento y análisis del comportamiento de los usuarios, ofreciendo información estadística del sitio web.</p>
            <p>Cookies de funcionalidad: Permiten recordar información para personalizar el servicio según las características del usuario.</p>
            <p>
              Cookies de redes sociales: Configuradas por servicios de redes sociales para compartir contenido a través de redes, haciendo seguimiento del navegador para crear un perfil de intereses.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="section-col-md">
            <h2 className="pt-4 h4 prim-color">3. ¿Quién utiliza las cookies?</h2>
            <p>
              La información recabada a través de las cookies desde el Portal de OAKMAN es utilizada por OAKMAN y, ocasionalmente, por terceros. Toda la información sobre el uso de cookies está
              disponible en el panel de configuración accesible desde el Portal.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="section-col-md">
            <h2 className="pt-4 h4 prim-color">4. Gestión de las cookies</h2>
            <p>
              El Usuario puede establecer sus preferencias y configurar el uso de cookies a través del panel de configuración accesible desde el banner publicado en el Portal. En cualquier momento, el
              Usuario puede modificar la configuración de cookies mediante el panel de configuración en el lateral derecho de la página o ajustando las preferencias del navegador.
            </p>
            <p>La configuración del navegador para evitar la instalación de cookies puede afectar al funcionamiento de algunas funcionalidades del Portal y la visualización de contenido.</p>
          </Col>
        </Row>
        <Row>
          <Col className="section-col-md">
            <h2 className="pt-4 h4 prim-color">5. Información sobre TID</h2>
            <p>
              El Usuario puede informarse sobre las Transferencias Internacionales de Datos (TID) a terceros países realizadas por terceros identificados en esta política de cookies en sus
              correspondientes políticas.
            </p>
          </Col>
        </Row>
      </Container>
    </CookiesHelmet>
  );
}

export default CookiesPolicy;
