import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyC2_0lp6zdXIMhdQQCVrsY3r4eMmsZmYAI",
  authDomain: "oakmanvs-pro.firebaseapp.com",
  projectId: "oakmanvs-pro",
  storageBucket: "oakmanvs-pro.appspot.com",
  messagingSenderId: "95964550289",
  appId: "1:95964550289:web:821407d9da2c8bb31a5b30",
  measurementId: "G-STC6X0NPCS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const analytics = getAnalytics(app);
export const functions = getFunctions(app);

if (process.env.NODE_ENV === "development") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}
