import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { HelmetProvider, Helmet } from "react-helmet-async";

function LegalHelmet({ children }) {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Aviso Legal - OAKMAN</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {children}
    </HelmetProvider>
  );
}

function Legal() {
  return (
    <LegalHelmet>
      <Container className="py-5">
        <Row>
          <Col className="section-col-md">
            <h1 className="prim-color pb-2">Aviso Legal</h1>
            <h2 className="pt-4 h4 prim-color">1. Introducción y datos de la compañía</h2>
            <p>
              Las presentes disposiciones regulan el uso del servicio del portal de Internet (en adelante, el “Portal”) que OAKMAN VENTURE STUDIO S.L. (en adelante, “OAKMAN”) pone a disposición de los
              usuarios de Internet.
            </p>
            <p>
              OAKMAN VENTURE STUDIO S.L. es una sociedad española con domicilio social en Calle de Fernández de la Hoz 48, 28010, Madrid, inscrita en el Registro Mercantil de Madrid, Tomo 46.611,
              Folio 159, Hoja M-818304.
            </p>
            <p>
              Para una comunicación directa y efectiva con OAKMAN se pone a disposición la dirección de correo electrónico <a href="mailto:contacto@oakmanvs.com">contacto@oakmanvs.com</a>.
            </p>
            <p>
              El simple acceso, navegación y uso del Portal atribuye la condición de usuario del mismo (en adelante, el “Usuario”) e implica la aceptación del Aviso Legal vigente y publicado en cada
              momento en el Portal.
            </p>
            <p>
              El Usuario deberá leer atentamente el presente Aviso Legal en cada una de las ocasiones en que se proponga utilizar el Portal, ya que éste y sus condiciones de uso recogidas en el
              presente Aviso Legal pueden sufrir modificaciones.
            </p>
            <p>
              Algunos servicios del Portal accesibles para los usuarios de Internet o exclusivos para los clientes de OAKMAN pueden estar sometidos a condiciones específicas, reglamentos e
              instrucciones que, en su caso, sustituyen, completan y/o modifican el presente Aviso Legal y que deberán ser aceptadas por el Usuario antes de iniciarse la prestación del servicio
              correspondiente.
            </p>
            <p>
              La información contenida y accesible a través de este Portal es facilitada por OAKMAN con objeto de proporcionar directrices generales y ofrecer al usuario información de interés general
              (los “Contenidos”).
            </p>

            <h2 className="pt-4 h4 prim-color">2. Acceso y uso del Portal</h2>
            <p>
              El acceso general y navegación a través del Portal no conlleva registro previo, salvo para aquellas funcionalidades y/o servicios que así lo requieran, en cuyo caso resultará necesario
              facilitar determinada información a través de los formularios dispuestos al efecto.
            </p>
            <p>
              La naturaleza de los contenidos del Portal y los servicios prestados a través del mismo se dirigen a usuarios mayores de 18 años, por tanto queda expresamente prohibido el acceso a
              usuarios menores de dicha edad.
            </p>
            <p>
              El Usuario se compromete a utilizar los Contenidos de conformidad con la Ley y el presente Aviso Legal, así como con las demás condiciones, reglamentos e instrucciones que en su caso
              pudieran ser de aplicación. Con carácter meramente enunciativo, el Usuario de acuerdo con la legislación vigente deberá abstenerse de:
            </p>
            <ul>
              <li>
                Reproducir, copiar, distribuir, poner a disposición, comunicar públicamente, transformar o modificar los Contenidos salvo en los casos autorizados en la ley o expresamente consentidos
                por OAKMAN o por quien ostente la titularidad de los derechos de explotación en su caso.
              </li>
              <li>
                Reproducir o copiar para uso privado los Contenidos que puedan ser considerados como software o base de datos de conformidad con la legislación vigente en materia de propiedad
                intelectual, así como su comunicación pública o puesta a disposición de terceros cuando estos actos impliquen necesariamente la reproducción por parte del Usuario o de un tercero.
              </li>
              <li>Extraer y/o reutilizar la totalidad o una parte sustancial de los Contenidos, así como de las bases de datos que OAKMAN ponga a disposición de los Usuarios.</li>
            </ul>
            <p>
              OAKMAN se reserva el derecho a modificar, suspender, cancelar o restringir el contenido del Portal, los vínculos o la información obtenida a través de ella, sin necesidad de previo
              aviso.
            </p>
            <p>
              Queda prohibido transmitir o enviar a través del Portal cualquier contenido ilegal o ilícito, virus informáticos, o mensajes que, en general, afecten o violen derechos de OAKMAN o de
              terceros.
            </p>

            <h2 className="pt-4 h4 prim-color">3. Blogs</h2>
            <p>Los Blogs accesibles a través del Portal permiten la publicación de comentarios por parte del Usuario, tras la indicación de su nombre y correo electrónico.</p>
            <p>En este sentido, queda informado de que sus comentarios, junto con los datos identificativos que aporte, podrán ser publicados en el Blog.</p>
            <p>
              El Usuario se compromete a no publicar ningún comentario que pudiese contravenir el presente Aviso Legal y será exclusivamente responsable de los comentarios que publique, así como de
              sus interacciones con el resto de usuarios.
            </p>
            <p>
              Los comentarios se encuentran en todo caso sujetos a moderación por lo que OAKMAN se reserva la facultad de denegar la publicación, o retirar, cualquier comentario o contenido que
              pudiese vulnerar lo establecido en el presente Aviso Legal.
            </p>

            <h2 className="pt-4 h4 prim-color">4. Ofertas de empleo</h2>
            <p>El Usuario puede acceder a Ofertas de empleo de OAKMAN a través del Portal.</p>
            <p>Para ello será necesario que lleve a cabo un proceso de registro con el fin de introducir sus datos curriculares y de interés profesional.</p>
            <p>El Usuario podrá en todo momento acceder con las credenciales facilitadas a la información introducida, así como modificar o eliminar aquello que considere.</p>
            <p>El tratamiento de los datos facilitados se regirá por lo establecido en la Política de Privacidad expuesta en el presente Aviso Legal y disponible en el Portal.</p>
            <p>Cualquier documentación o información que exceda de la necesaria para la valoración del perfil profesional será eliminada.</p>
            <p>
              Concretamente, en ningún momento, OAKMAN tratará datos de carácter personal relativos a salud (salvo el porcentaje de discapacidad), afiliación sindical, ideología, religión, creencias,
              origen racial o vida sexual.
            </p>

            <h2 className="pt-4 h4 prim-color">5. Propiedad Intelectual e Industrial</h2>
            <p>
              Dado que los Contenidos (incluyendo, sin carácter limitativo, bases de datos, imágenes, fotografías, patentes, modelos de utilidad e industriales, dibujos, gráficos, archivos de texto,
              audio, vídeo y software) del presente Portal están protegidos por copyright y marca registrada, el uso no autorizado de cualquier Contenido incluido en el Portal puede incumplir el
              copyright, la marca registrada u otros derechos de propiedad industrial e intelectual protegidos por la ley aplicable.
            </p>
            <p>
              La recopilación (entendiéndose como tal la obtención, diseño, ordenación y montaje) de todo el contenido del Portal es propiedad exclusiva de OAKMAN, y se encuentra protegida por la
              normativa aplicable en materia de propiedad industrial e intelectual.
            </p>
            <p>
              Todo el software, texto, dibujos gráficos, vídeos o soportes de audio utilizados en el uso y desarrollo del Sitio Web es propiedad de OAKMAN o de sus proveedores de software y se
              encuentra protegido por las leyes de propiedad industrial e intelectual.
            </p>
            <p>
              La puesta a disposición de los usuarios de las bases de datos, imágenes, fotografías, patentes, modelos de utilidad e industriales, dibujos, gráficos, archivos de texto, audio, vídeo y
              software propiedad de OAKMAN o de sus proveedores a través del Portal o que figuran en el mismo no implica, en ningún caso, la cesión de su titularidad o la concesión de un derecho de
              explotación a favor del usuario distinto del uso que comporta la utilización legítima y acorde con la naturaleza del Portal.
            </p>
            <p>
              Si el Usuario descargase Contenidos del Portal para un uso personal o no-comercial, debe conservar todas las notificaciones de copyright, marca registrada u otros avisos similares
              incluidos en el material original o en cualquier copia de material.
            </p>
            <p>
              Queda prohibido reproducir, difundir, transformar, transmitir por cualquier medio, posterior publicación, exhibición, comunicación pública o representación total o parcial, modificar o
              suprimir la información, los Contenidos u advertencias de este Portal sin la previa autorización escrita de OAKMAN.
            </p>
            <p>En caso de producirse, se atenderá al régimen sancionador de las infracciones de los derechos de propiedad intelectual de OAKMAN, amparado por la legislación vigente.</p>
            <p>OAKMAN y el logotipo de OAKMAN son marcas registradas de OAKMAN, así como los rótulos, signos distintivos o logos.</p>
            <p>La utilización de estas marcas requiere la autorización expresa de OAKMAN, así como un contrato de licencia acordado expresamente por las partes.</p>
            <p>El uso no autorizado de estas marcas u otras marcas registradas de OAKMAN podría ser objeto de procedimiento judicial en la máxima medida posible contemplada por la ley.</p>
            <p>
              Para solicitar dicha autorización por escrito, póngase en contacto a través del formulario de "Contacto" o en la dirección{" "}
              <a href="mailto:contacto@oakmanvs.com">contacto@oakmanvs.com</a>.
            </p>
            <p>
              En este Portal se pueden mencionar de forma indirecta y a nivel informativo algunos términos y logotipos, que son marcas registradas por sus respectivos propietarios, no haciéndonos
              responsables de la utilización inadecuada que pudieran hacer terceras personas respecto a dichas marcas.
            </p>
          </Col>
        </Row>
      </Container>
    </LegalHelmet>
  );
}

export default Legal;
