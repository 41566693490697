import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../LandingPage.css";

function Credentials() {
  const sections = [
    { src: "./imgs/cr-banco-santander.webp", alt: "Banco Santander" },
    { src: "./imgs/cr-european-commission.webp", alt: "European Commission" },
    { src: "./imgs/cr-amazon.webp", alt: "Amazon" },
    { src: "./imgs/cr-bbva.webp", alt: "BBVA" },
    { src: "./imgs/cr-alibaba.webp", alt: "Alibaba" },
    { src: "./imgs/cr-melia-hotels.webp", alt: "Melia Hotels" },
  ];

  return (
    <Container>
      <Row>
        <Col className="section-col-md">
          <p className="credentials-title prim-color pb-2">
            No son rumores. <br /> <span className="highlight">Es una década de experiencia.</span>
          </p>
        </Col>
      </Row>
      <Row>
        {sections.map((section, index) => (
          <Col lg={2} md={4} xs={6} className="section-col-md" key={index}>
            <img src={require(`${section.src}`)} alt={section.alt} width={"190"} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Credentials;
