import React from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import Logo from "../../../assets/imgs/oakman-logo.png";
import { FaLinkedin } from "react-icons/fa";

import "./Footer.css";

function Footer() {
  return (
    <>
      <div className="footer-prim-bg">
        <Container>
          <Row className="align-items-center py-4">
            <Col lg={2} className="section-col-md d-flex justify-content-center py-2">
              <a href="/">
                <img src={Logo} alt="OAKMAN" width="180px" height="auto" />
              </a>
            </Col>
            <Col lg={8} className="section-col-md py-2">
              <Nav className="justify-content-center flex-column flex-md-row text-center">
                <Nav.Link href="/#mission">Nuestra misión</Nav.Link>
                <Nav.Link href="/#benefits">Nuestro enfoque</Nav.Link>
                <Nav.Link href="/#services">Nuestros servicios</Nav.Link>
                <Nav.Link href="/#contact">Contacto</Nav.Link>
              </Nav>
            </Col>
            <Col lg={2} className="section-col-md d-flex justify-content-center py-2">
              <div className="icon-circle">
                <a href="https://www.linkedin.com/company/oakman" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin size={30} />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-sec-bg border-top">
        <Container>
          <Row>
            <Col>
              <div className="pt-4 pb-2">
                <p className="text-center small"><a href="/legal">Aviso Legal</a> | <a href="/privacy-policy">Política de privacidad</a> | <a href="/cookies-policy">Política de cookies</a></p>
                <p className="text-center small">© 2024 OAKMAN. Todos los derechos reservados.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Footer;
