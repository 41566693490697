import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../LandingPage.css";

const sections = [
  {
    iconPath: "./imgs/icon-services-use-cases.webp",
    title: "Identificación de Casos de Uso de IA y plan de trabajo",
    subtitle: "El primer paso hacia una nueva cima",
    description:
      "Analizamos tus sistemas y tus procesos para identificar las áreas de tu empresa donde la IA puede generar mejoras significativas. Te recomendamos la mejor tecnología y los mejores modelos para abordar tus necesidades, y elaboramos un plan de trabajo detallado para cumplir con tus expectativas.",
  },
  {
    iconPath: "./imgs/icon-services-automation.webp",
    title: "Automatización y optimización de procesos con IA",
    subtitle: "Céntrate sólo en las tareas que aportan valor a tu negocio",
    description:
      "Con un enfoque centrado en la aplicación de técnicas avanzadas de Aprendizaje Automático (ML) y Deep Learning, en OAKMAN estamos especializados en el desarrollo de soluciones y modelos personalizados para la automatización y optimización de procesos empresariales.",
  },
  {
    iconPath: "./imgs/icon-services-genai.webp",
    title: "Implementación de IA Generativa",
    subtitle: "Lo que has visto hasta ahora es solo una muestra",
    description:
      "Entrenamos modelos de Inteligencia Artificial Generativa utilizando bases de conocimiento específicas de tu negocio para optimizar su rendimiento en dominios específicos. Este enfoque permite que los modelos comprendan y respondan con precisión a consultas dentro de contextos especializados.",
  },
  {
    iconPath: "./imgs/icon-services-learning.webp",
    title: "Formación en IA para directivos y equipos",
    subtitle: "Adelántate a la transformación",
    description:
      "Nuestras sesiones y programas de formación en IA son la oportunidad perfecta para adentrarte en el mundo de la Inteligencia Artificial. Descubrirás cómo impulsar el crecimiento de tu empresa mediante el uso estratégico de IA, identificarás cuándo es apropiado implementar sistemas de Inteligencia Artificial y aprenderás a ofrecer resultados de alto valor tanto a tus colaboradores como a tus clientes.",
  },
];

function Services() {
  return (
    <>
      <Container>
        <Row className="section-row">
          <Col className="section-col-md px-lg-0">
            <p className="services-title prim-color">
              Una oportunidad <span className="sec-color">única</span> necesita unos servicios <span className="sec-color">únicos</span>.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={10}>
            <Row>
              {sections.map((section, index) => (
                <Col key={index} lg={{ span: 10, offset: index % 2 === 0 ? 1 : 0 }} className="mb-4">
                  <Card className={`h-100 p-2 ${index % 2 === 0 ? "card-glow-primary" : "card-glow-secondary"}`}>
                    <Card.Body>
                      <div className={`d-flex align-items-center mb-3 ${index % 2 === 0 ? "justify-content-start" : "justify-content-end"}`}>
                        {index % 2 === 0 ? (
                          <>
                            <div className="card-icon me-3">
                              <img src={require(`${section.iconPath}`)} alt="Icono" width="50" height="50" />
                            </div>
                            <div>
                              <Card.Title className="prim-color">{section.title}</Card.Title>
                              <Card.Subtitle className="sub-color">{section.subtitle}</Card.Subtitle>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-md-end">
                              <Card.Title className="sec-color">{section.title}</Card.Title>
                              <Card.Subtitle className="sub-color">{section.subtitle}</Card.Subtitle>
                            </div>
                            <div className="card-icon ms-3">
                              <img src={require(`${section.iconPath}`)} alt="Icono" width="50" height="50" />
                            </div>
                          </>
                        )}
                      </div>
                      <Card.Text>{section.description}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Services;
