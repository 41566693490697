import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { HelmetProvider, Helmet } from "react-helmet-async";

function PrivacyPolicyHelmet({ children }) {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Política de Privacidad - OAKMAN</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {children}
    </HelmetProvider>
  );
}

function PrivacyPolicy() {
  return (
    <PrivacyPolicyHelmet>
      <Container className="py-5">
        <Row>
          <Col className="section-col-md">
            <h1 className="prim-color pb-2">Política de Privacidad</h1>
            <p>OAKMAN se compromete a proteger la confidencialidad y la privacidad de los datos de carácter personal confiados a la Firma.</p>
            <p>Última actualización: 18 de julio del 2024.</p>
          </Col>
        </Row>
        <Row>
          <Col className="section-col-md">
            <h2 className="pt-4 h4 prim-color">1. Información básica</h2>
            <p>
              <span className="fw-bold">Responsable:</span> OAKMAN VENTURE STUDIO S.L.
            </p>
            <p>
              <span className="fw-bold">Finalidad:</span> La atención de solicitudes de diversa índole por parte del Usuario o titulares de los datos (en adelante, “Usuario”).
            </p>
            <p>
              <span className="fw-bold">Legitimación:</span> Consentimiento del interesado, interés legítimo.
            </p>
            <p>
              <span className="fw-bold">Derechos:</span> Acceder, Rectificar y Suprimir los Datos, así como otros derechos, como se explica en la Información Adicional.
            </p>
            <p>
              <span className="fw-bold">Información adicional:</span> Consulte la Información Adicional y detallada sobre Protección de Datos.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="section-col-md">
            <h2 className="pt-4 h4 prim-color">2. Información adicional</h2>
            <p>
              Las presente Política de Privacidad regula el tratamiento de datos personales facilitados por el Usuario a través del portal de Internet (en adelante, el “Portal”) que OAKMAN VENTURE
              STUDIO S.L. (en adelante, “OAKMAN”) pone a disposición de los usuarios de Internet. La presente Política forma parte integrante del Aviso Legal accesible en todo momento desde el Portal.
            </p>
            <p>
              El Usuario garantiza que los datos aportados son verdaderos, exactos, completos y actualizados, siendo responsable de cualquier daño o perjuicio, directo o indirecto, que pudiera
              ocasionarse como consecuencia del incumplimiento de tal obligación. En el caso de que los datos aportados pertenecieran a un tercero, el Usuario garantiza que ha informado a dicho
              tercero de los aspectos contenidos en este documento y obtenido su autorización para facilitar sus datos a OAKMAN para los fines señalados.
            </p>
            <p>
              El Portal puede ofrecer funcionalidades para compartir contenidos a través de aplicaciones de terceros, como Facebook o Twitter. Estas aplicaciones pueden recoger y tratar información
              relacionada con la navegación del usuario en los diferentes sitios webs. Cualquier información personal que se recabada a través de estas aplicaciones, puede ser utilizada por terceros
              usuarios de las mismas, y sus interacciones están sometidas a las políticas de privacidad de las compañías que facilitan las aplicaciones.
            </p>
            <p>
              El Portal puede alojar blogs, foros, y otras aplicaciones o servicios de redes sociales con la finalidad de facilitar el intercambio de conocimiento y contenido. Cualquier información
              personal que se facilite por el usuario puede ser compartida con otros usuarios de ese servicio, sobre los cuales OAKMAN no tiene control alguno.
            </p>
            <p>
              Con objeto de ofrecer información o servicios de interés en función de la localización del Usuario, OAKMAN podrá acceder a datos relativos a la geolocalización del dispositivo del
              Usuario, en aquellos casos en que la configuración del usuario al efecto así lo permita.
            </p>
            <p>
              A efectos de seguridad técnica y diagnóstico de sistemas, de forma anonimizada o agregada, OAKMAN podrá registrar la dirección IP (número de identificación del acceso a Internet del
              dispositivo, que permite a los dispositivos, sistemas y servidores reconocerse y comunicarse entre sí). Dicha información podrá ser también empleada con finalidades analíticas de
              rendimiento web.
            </p>
            <p className="pt-3 h5 prim-color">¿Quién es el responsable del tratamiento de los datos personales del Usuario?</p>
            <p>
              Identidad: OAKMAN VENTURE STUDIO S.L. es una sociedad española con domicilio social en Calle de Fernández de la Hoz 48, 28010, Madrid, inscrita en el Registro Mercantil de Madrid, Tomo
              46.611, Folio 159, Hoja M-818304.
            </p>
            <p>Dirección postal: Calle de Fernández de la Hoz 48, 28010, Madrid,</p>
            <p>Teléfono: +34 696 747 490</p>
            <p>
              Correo electrónico:{" "}
              <a className="prim-color" href="mailto:contacto@oakmanvs.com">
                contacto@oakmanvs.com
              </a>
            </p>
            <p>
              La Dirección de OAKMAN promueve y apoya la implantación de las medidas técnicas y organizativas necesarias para minimizar los potenciales riesgos a los que se encuentran expuestos los
              datos personales.
            </p>
            <p className="pt-3 h5 prim-color">2.1. ¿Con qué finalidad tratamos los datos personales del Usuario?</p>
            <p>
              OAKMAN trata la información facilitada por el Usuario con el fin de atender las solicitudes de diversa índole llevadas a cabo por parte de este. En función de la naturaleza de la
              solicitud, la finalidad perseguirá la gestión de (i) consultas y solicitudes de propuestas, (RFP) remitidas a través del formulario de contacto habilitado al efecto, (ii) curricula
              remitidos a través del Portal, (iii) la participación en blogs, mediante comentarios, (iv) la suscripción a contenido exclusivo de OAKMAN.
            </p>
            <p>
              OAKMAN podrá elaborar un perfil comercial con base en la información facilitada por el Usuario y las interacciones de este con los contenidos ofrecidos con la finalidad de enviarle
              información personalizada acerca de nuestros productos o servicios, incluso por medios electrónicos. La base legitimadora del perfilado es el interés legítimo de OAKMAN de conocer el
              perfil del Usuario interesado en sus comunicaciones y servicios para ofrecerle información y contenidos personalizados adaptados a sus intereses y actividad. No se tomarán decisiones
              automatizadas con base en dicho perfil. El Usuario en cualquier momento podrá oponerse al perfilado de su información a través del canal habilitado al efecto.
            </p>
            <p>
              En el supuesto de que el Usuario se registre en el Portal a través del login social, OAKMAN únicamente accederá a los datos personales del Usuario para los que haya prestado su
              consentimiento durante la configuración del acceso de la red social de que se trate. Cualquier información facilitada en conexión con dichas aplicaciones sociales podría ser accedida por
              miembros de la correspondiente red social, dichas interacciones se regirán por las políticas de privacidad de las entidades prestadoras de los servicios. OAKMAN no dispone de ningún
              control ni responsabilidad respecto de dichas entidades o del uso que realicen de la información del usuario.
            </p>
            <p className="pt-3 sec-color">2.1.1. Información acerca del tratamiento de datos para el contacto y envío de comunicaciones desde OAKMAN</p>
            <p>
              Los datos empleados, o facilitados, en las comunicaciones informativas y/o promocionales son tratados por OAKMAN para finalidades consistentes en el envío electrónico de información y
              comunicaciones sobre servicios, actividades, publicaciones, celebraciones, felicitaciones y eventos o acontecimientos sociales y profesionales de OAKMAN; el seguimiento y optimización de
              las campañas de marketing realizadas mediante tecnologías al efecto; y la elaboración de perfiles con finalidades comerciales empleando datos de contacto y sus preferencias.
            </p>
            <p>
              Constituye base jurídica de los referidos tratamientos el consentimiento expreso otorgado por el titular. En cada una de las comunicaciones recibidas podrá darse de baja de dichos envíos
              mediante el mecanismo habilitado al efecto. El criterio de conservación de los datos tendrá base en la manifestación contraria al tratamiento por su parte. En todo caso, podrán
              ejercitarse los derechos de acceso, rectificación, oposición, supresión, portabilidad y limitación del tratamiento, así como plantear cualquier consulta respecto del tratamiento de sus
              datos personales a través del correo electrónico{" "}
              <a className="prim-color" href="mailto:contacto@oakmanvs.com">
                contacto@oakmanvs.com
              </a>
              . Para contactar con el Delegado de Protección de Datos podrá dirigirse a la dirección electrónica{" "}
              <a className="prim-color" href="mailto:contacto@oakmanvs.com">
                contacto@oakmanvs.com
              </a>
              . Los titulares de los datos podrán presentar así mismo reclamación, en caso de insatisfacción en el ejercicio de sus derechos, ante la Agencia Española de Protección de Datos, a través
              de su sede electrónica mediante el procedimiento electrónico de presentación de denuncias.
            </p>
            <p className="pt-3 h5 prim-color">2.2. ¿Por cuánto tiempo conservaremos los datos personales del Usuario?</p>
            <p>
              Con carácter general, los datos personales proporcionados se conservarán durante el tiempo necesario para atender la solicitud del Usuario o mientras no se solicite su supresión por el
              Usuario. En el caso concreto de currículum los datos serán conservados por un periodo máximo de dos años, salvo indicación en contrario por parte del interesado.
            </p>
            <p className="pt-3 h5 prim-color">2.3. ¿Cuál es la legitimación para el tratamiento de los datos personales del Usuario?</p>
            <p>
              Con carácter general, la base legal para el tratamiento de los datos es la legitimación por consentimiento del Usuario y en los supuestos de perfilado de Usuarios, la base legitimadora
              será el interés legítimo de OAKMAN de conocer el perfil del Usuario interesado en sus comunicaciones y servicios para ofrecerle información y contenidos personalizados adaptados a sus
              intereses y actividad.
            </p>
            <p className="pt-3 h5 prim-color">2.4. ¿A qué destinatarios se comunicarán los datos personales del Usuario?</p>
            <p>
              OAKMAN no tiene intención de llevar a cabo, de forma proactiva, transferencias internacionales de los datos personales de los Usuarios. No obstante, en aquellos casos en que para atender
              la solicitud del Usuario se requerirá facilitar datos identificativos a otras entidades afiliadas a OAKMAN, incluidas aquellas ubicadas fuera del Espacio Económico Europeo, OAKMAN
              garantiza la adopción de las garantías adecuadas, mediante la suscripción de un contrato de acuerdo con los contenidos recogidos en las cláusulas contractuales tipo vigentes establecidas
              por la Comisión Europea.
            </p>
            <p>Así mismo, los datos podrán ser cedidos a administraciones y organismos públicos para el cumplimiento de obligaciones directamente exigibles a OAKMAN.</p>
            <p>
              En aquellos casos en que el Usuario acepte la asistencia a eventos organizados o patrocinados por OAKMAN en colaboración con otra/s entidad/es patrocinadoras, coorganizadoras o
              proveedoras de servicios, los datos personales podrán ser facilitados a éstas, con base en el interés legítimo de identificar a los asistentes al evento que organizan o patrocinan, así
              como el control de los accesos a las instalaciones donde se celebre el evento.
            </p>
            <p className="pt-3 h5 prim-color">2.5. ¿Cuáles son los derechos del Usuario cuando nos facilita sus datos personales?</p>
            <p>
              El Usuario tiene derecho a obtener confirmación sobre si OAKMAN trata datos personales que le conciernen, así como a acceder a sus datos personales, solicitar la rectificación de los
              datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.
            </p>
            <p>
              En determinadas circunstancias, el Usuario podrá solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente se conservarán para el ejercicio o la defensa de
              reclamaciones.
            </p>
            <p>
              En determinadas circunstancias y por motivos relacionados con su situación particular, el Usuario podrá oponerse al tratamiento de sus datos. OAKMAN cesará en el tratamiento de los
              datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.
            </p>
            <p>
              El Usuario podrá ejercer el derecho a la portabilidad de sus datos, en un formato estructurado, de uso común y lectura mecánica, y a transmitirlos a otro responsable del tratamiento.
              Dicha acción podría conllevar un canon razonable a abonar por el Usuario, en función de los costes administrativos afrontados para satisfacer la solicitud, de conformidad con la
              normativa.
            </p>
            <p>
              El Usuario podrá plantear las cuestiones que considere en relación a la presente Política así como ejercer sus derechos en los términos legalmente previstos debiendo para ello dirigir
              una comunicación con indicación de la solicitud correspondiente y acompañado de copia del DNI o documento acreditativo de la identidad.
            </p>
            <p>
              En caso de no obtener satisfacción en el ejercicio de sus derechos, podrá presentar una reclamación ante la Agencia Española de Protección de Datos a través de su sede electrónica, en
              presentación de una reclamación de tutela de derechos.
            </p>
            <p className="pt-3 h5 prim-color">2.6. ¿Qué cookies utiliza la página web de OAKMAN?</p>
            <p>
              La página web de OAKMAN utiliza dispositivos de almacenamiento, cookies, así como de tecnologías similares. Para ampliar la información sobre las cookies y estas tecnologías puede
              consultar la Política de Cookies.
            </p>
          </Col>
        </Row>
      </Container>
    </PrivacyPolicyHelmet>
  );
}

export default PrivacyPolicy;
