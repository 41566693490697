import React from "react";
import Hero from "./Hero/Hero";
import Mission from "./Mission/Mission";
import Benefits from "./Benefits/Benefits";
import Services from "./Services/Services";
import Credentials from "./Credentials/Credentials";
import Contact from "./Contact/Contact";
import "./LandingPage.css";

function LandingPage() {
  return (
    <>
      <section className="py-0">
        <Hero />
      </section>
      <section id="mission">
        <Mission />
      </section>
      <section id="benefits">
        <Benefits />
      </section>
      <section id="credentials">
        <Credentials />
      </section>
      <section id="services">
        <Services />
      </section>
      <section id="contact">
        <Contact />
      </section>
    </>
  );
}

export default LandingPage;
